import React from "react"
import PrivacyPolicy1 from "../sections/privacy-policy/privacy-policy-1"

const privacy = () => {
  return (
    <div>
      <PrivacyPolicy1 />
    </div>
  )
}

export default privacy
